import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
  Dropdown,
  DropdownButton,
  FloatingLabel,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { auth, fires, analytics, storageRef } from "../firebase";
import { Link, useNavigate, Route, useParams } from "react-router-dom";
import { isMobile, isAndroid, isIOS, isDesktop } from "react-device-detect";
import uuid from "react-uuid";
import Youtube from "react-youtube";
import ReactPlayer from 'react-player/youtube'
import { height, width } from "dom-helpers";
import firebase from "firebase/app";
import "firebase/firestore";
import Countdown from "react-countdown";
import { Helmet } from "react-helmet";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import {
  FaClock,
  FaCalendarAlt,
  FaLandmark,
  FaGlassCheers,
  FaGlobeAmericas,
} from "react-icons/fa";
import {
  sendEventEmail,
  sendEventCheckinEmail,
  sendEventTicketPurchaseEmail,
  sendEventInvitedEmail,
  createSystemLoginNotification,
} from "../contexts/API";
import Moment from "moment";
import { QRCodeCanvas } from "qrcode.react";
import { useTranslation } from "react-i18next";
import Announcement from 'react-announcement'
import axios, {isCancel, AxiosError} from 'axios';
import SimpleFooter from "./Home/SimpleFooter";

export default function EventDetails() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [registerType, setRegisterType] = useState(0);
  const { currentUser, logout, signup, login } = useAuth();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const profileString =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
  const privacyLink = "https://www.leyuanreview.com/privacy";

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState(profileString);
  const [uploadS, setUploadS] = useState("请点击上传图片开始上传")
  const [uploadedURL, setUploadedURL] = useState('')

  const lngs = [
    { code: "en", native: "English" },
    { code: "zh", native: "简体中文" },
  ];

  const [posts, setPosts] = useState([]);
  const [eventInfo, setEventInfo] = useState({
    status: "NA",
    title: "",
    description: "",
    announcement: "",
    titles: { en: "", zh: "" },
    descriptions: { en: "", zh: "" },
    priceType: "FREE",
    type: "event",
  });
  const [eventOptions, setEventOptions] = useState({
    poll:false,
    draw:false,
    namecard:false,
    review:false,
    comment:true,
    picture:true,
    socialmedia:true,
    hidehost:false,
    request: false,
    'register-feedback':false,
    'accept-registration':true,
    'multi-ticket-checkout': false,
    max:150})
  const [videoURL, setVideoURL] = useState("");
  const [similarEvents, setSimilarEvents] = useState([]);
  const [contents, setContents] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [images, setImages] = useState([]);

  const [checkInMembers, setCheckInMembers] = useState([]);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const [checkInEmails, setCheckInEmails] = useState([]);

  const [eventMessages, setEventMessages] = useState([]);
  const [eventDetailsInfo, setEventDetailsInfo] = useState({
    title: eventInfo.title,
    descriptions: [eventInfo.description],
    orgName: "",
    orgURL: "",
    refundPolicy: "",
    language: "zh",
  });
  const [totalTicketCount, setTotalTicketCount] = useState(0)
  const [cohosts, setCoHosts] = useState([

  ])
  const [speakers, setSpeakers] = useState([

  ])
  const [userInfo, setUserInfo] = useState({
    uid: "",
    email: "",
    name: "",
    firstName: "",
    lastName: "",
    work: "",
    wechat: "",
  });
  const [memberMeInfo, setMemberMeInfo] = useState({
    email: "",
    name: "",
    work: "",
    checkinStatus: false,
    ticketId: "",
    checkinTickets: [],
    tickets: [],
    profileURL: profileString,
    invitedMembers: [],
  });
  const [eventPriceOptions, setEventPriceOptions] = useState({
    priceType: "FREE",
    price: 0,
    priceOriginal: 0,
    priceId: "",
    productId: "",
    priceCurrency: "CAD",
    priceEMTEmail: "",
  });

  const [showCheckIn, setShowCheckIn] = useState(false);
  const [showInvited, setShowInvited] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [eventIDError, setEventIDError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showLoginRegister, setShowLoginRegister] = useState(false);
  const [loginCode, setLoginCode] = useState(0);
  const [showLeaveMessage, setShowLeaveMessage] = useState(false);

  const eventInviteeFirstNameRef = useRef();
  const eventInviteeLastNameRef = useRef();
  const eventInviteeEmailRef = useRef();

  let { id, lang } = useParams();
  const maxW = isMobile ? "100%" : "80%";


  function routeToCheckIn(eventId) {
    window.location.href = "/event-signup/" + eventId;
  }

  function routeToLiveEventDashboard() {
    window.location.href = "/live-event-dashboard/" + id;
  }

  function routeToPurchaseTicket() {
    window.location.href = "/event-registration/" + id + "/" + i18n.language;
  }

  function getEventDateString(dateF) {
    return Moment(dateF).format("YYYY-MM-DD");
  }

  function getEventTimeString(dateF) {
    Moment(dateF).format("HH:mm");
  }

  function selectLanguage(e){
    if (e == "more-language") {
      window.alert(
        "We currently do not support more languages, if you would like event to have more languages, please contact us at admin@letjoy.app"
      );
    } else {
      if (e == "zh") {
        window.location.href = "/" + id + "/zh"
      } else if (e == "en") {
        window.location.href = "/" + id + "/en"
      }
    }
  }

  async function checkTicketStatus(){
    await fetch("https://us-central1-blink-574af.cloudfunctions.net/eventsAPICheckTicketStatus?event_id="+id, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    })
    .then(response => response.json())
    .then((data) => {
      let tickCount = 0
      if (data.ticketsCount != null){
        tickCount = data.ticketsCount
        setTotalTicketCount(tickCount)
      }
    })
  }

  async function checkCurrentTickets(){
    //check if we have tickets left

    // var tCount = 0
    // fires
    // .collection('events')
    // .doc(id)
    // .collection('tickets')
    // .get()
    // .then((snapshot)=>{
    //   tCount = snapshot.docs.length
    //   setTotalTicketCount(tCount)
    // })

    checkTicketStatus()
  }

  async function handleInfoSubmit(e) {
    e.preventDefault();

    const emailA = e.target[0].value;
    const nameU = e.target[1].value;
    const workU = e.target[2].value;
    const profileUrl = uploadedURL

    if (id !== "" && memberMeInfo.email != "") {
      
      fires
        .collection("events")
        .doc(id)
        .collection("members")
        .doc(memberMeInfo.email)
        .update(
          { name: nameU, 
            work: workU, 
            profileURL: profileUrl 
          })
        .then(() => {
          window.location.reload()
        });
      
    }
  }

  function uploadImage(file) {
    fetch('https://r2-worker.sam-12e.workers.dev/', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': file.type
      },
      body: file
    })
      .then((response) => response.text())
      .then((data) => {
        const downloadURL = "https://pub-e888c0363b7c412caf77f22ba84fcb86.r2.dev/" + data
        setSelectedImageURL(downloadURL)
        setSelectedImage(null)
        setUploadedURL(downloadURL)
      }) // 
      .catch((error) => console.error(error));
  }

  const handleImageChange = (e) => {
    e.preventDefault()
    const file = e.target.files[0];
    setSelectedImage(file)
  }

  const handleImageSubmit = (e) => {
    e.preventDefault()
    const file = selectedImage;
    if (!file) return;

    uploadImage(file)

    
  }

  async function loadSimilarEvents(){
    fires
        .collection('events')
        .where('startDate','>=',firebase.firestore.Timestamp.fromDate(new Date()))
        .where('status', '==', 'live')
        .orderBy('startDate','asc')
        .get()
        .then((snapshot) => {
            var allEvents = []
            snapshot.forEach((doc) => {
                const eventData = doc.data()
                allEvents.push({ ...eventData, id: doc.id})
            })
            setSimilarEvents(allEvents)
        })
  }

  function createMemberJoinLog(eType, uEmail, uidd) {
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    const joinLog = {
      timestamp: timestampNow,
      type: eType,
      email: uEmail,
      uid: uidd,
    };
    fires.collection("events").doc(id).collection("logs").add(joinLog);
  }

  function createLinkVisitLog(linkVisited, linkName) {
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    var ua = window.navigator.userAgent.toLowerCase();
    const platformName = "web";
    let deviceName = "";
    if (isAndroid) {
      deviceName = "android";
    } else if (isIOS) {
      deviceName = "ios";
    } else {
      deviceName = "web";
    }
    let fuid = "";
    if (auth.currentUser !== null) {
        fuid = auth.currentUser.uid;
    }
    var viewLang = i18n.language
    var lName = ""
    var linkV = ""
    if (linkName != null){
      lName = linkName
    }
    if (linkVisited != null){
      linkV = linkVisited
    }
    const viewData = {
        fromUA: ua,
        fromUID: fuid,
        platform: platformName,
        device: deviceName,
        lang: viewLang,
        linkName: lName,
        linkURL: linkV,
        timestamp: timestampNow,
      };
    fires.collection("events").doc(id).collection("views").add(viewData);
  }

  async function handleCheckInSubmit(e) {
    e.preventDefault();

    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    const checkinID = e.target[0].value;
    const checkinEmail = e.target[1].value;
    if (checkinID === posts[0].pin) {
      fires
        .collection("events")
        .doc(id)
        .collection("members")
        .doc(checkinEmail)
        .update({ checkinStatus: true, checkinTimestamp: timestampNow })
        .then(() => {
          fires
            .collection("users")
            .doc(currentUser.uid)
            .update({ currentEvent: id });

            createMemberJoinLog("checkin",checkinEmail,currentUser.uid)

          setShowCheckIn(false);
          window.location.reload();
        });
    } else {
      if (i18n.language == "en") {
        alert("Your checkin id is not correct, please contact host");
      } else {
        alert("活动ID不正确，请您联系活动组织者");
      }
    }
  }

  function createTicketId() {
    const fNumber = Math.floor(1000 + Math.random() * 9000);
    const sNumber = Math.floor(1000 + Math.random() * 9000);
    const tNumber = Math.floor(1000 + Math.random() * 9000);
    const ttNumber = Math.floor(1000 + Math.random() * 9000);
    const idString =
      fNumber.toString() + "-" + sNumber.toString() + "-" + tNumber.toString() + "-" + ttNumber.toString();
    return idString;
  }

  function eventIsTour() {
    let eventIsTour = (eventInfo.type == "tour")
    return eventIsTour
  }


  function handleEventRegisteration(e) {
    if (eventIsTour()){
        //tour
        window.location.href = "/tour-purchase-ticket/" + id
    }
    else{

      if (eventOptions.request){
        window.location.href = "/event-request/" + id
      }
      else{
        if (eventOptions["multi-ticket-checkout"] == true){
          window.location.href = "/event-purchase-ticket/" + id + "/" + i18n.language
        }
        else{
          routeToPurchaseTicket();
        }
      }

    }
  }

  async function handleUserRegister(e) {
    window.location.href = "/signup"
    
  }
    
  async function handleUserLogin(e) {
      window.location.href = "/login"
  }

  useEffect(() => {
    window.location.assign('https://evtgo.com/e/'+id)
    // fires
    //   .collection("events")
    //   .doc(id)
    //   .get()
    //   .then((snapshot) => {
    //     if (snapshot.exists) {
    //       const posts = [];
    //       const postData = snapshot.data();
    //       var announcement = ""
    //       if (postData.alertMessage != null){
    //         announcement = postData.alertMessage
    //       }
    //       if (announcement != ""){
    //         window.confirm(announcement)
    //       }
          

    //       setEventInfo({ ...postData, announcement: announcement });

    //       if (postData.videoURL != null){
    //         if (postData.videoURL != ""){
    //           setVideoURL(postData.videoURL)
    //         }
    //       }

    //       if (postData.photoURLs != null) {
    //         var photoUs = [];
    //         postData.photoURLs.map((photoURL) => {
    //           photoUs.push(photoURL);
    //         });
    //         setImages(photoUs);
    //       }
    //       if (postData.options != null){
    //         let eventRequest = false
    //         if (postData.options.request != null){
    //           eventRequest = postData.options.request
    //         }
    //         setEventOptions({request: eventRequest, ...postData.options})
    //       }
    //       if (postData.speakers != null){
    //         setSpeakers(postData.speakers)
    //       }

    //       var localeTitle = postData.title;
    //       var localeDescriptions = [postData.description];
    //       var localeOrgName = "";
    //       var localeUserOrgURL = "";
    //       if (postData.user.userOrg != null) {
    //         localeOrgName = postData.user.userOrg;
    //       }
    //       if (postData.user.cohosts != null){
    //         setCoHosts(postData.user.cohosts)
    //       }

    //       if (lang != null) {
    //         if (lang != "") {
    //           if (lang == "zh") {
    //             i18n.changeLanguage("zh");

    //             if (postData.titles != null) {
    //               if (postData.titles.zh != null) {
    //                 localeTitle = postData.titles.zh;
    //               }
    //             }
    //             if (postData.descriptions != null) {
    //               if (postData.descriptions.zh != null) {
    //                 localeDescriptions = postData.descriptions.zh;
    //               }
    //             }
    //             if (postData.user.userOrgNames != null) {
    //               if (postData.user.userOrgNames.zh != null) {
    //                 localeOrgName = postData.user.userOrgNames.zh;
    //               }
    //             }
    //             if (postData.user.userOrgURLs != null) {
    //               if (postData.user.userOrgURLs.zh != null) {
    //                 localeUserOrgURL = postData.user.userOrgURLs.zh;
    //               }
    //             }
    //             let refundPolicy = ""
    //             if (postData.refundPolicy != null){
    //               refundPolicy = postData.refundPolicy
    //             }
    //             setEventDetailsInfo({
    //               title: localeTitle,
    //               descriptions: localeDescriptions,
    //               orgName: localeOrgName,
    //               orgURL: localeUserOrgURL,
    //               language: lang,
    //               refundPolicy: refundPolicy,
    //             });
    //           } else if (lang == "en") {
    //             i18n.changeLanguage("en");

    //             if (postData.titles != null) {
    //               if (postData.titles.en != null) {
    //                 localeTitle = postData.titles.en;
    //               }
    //             }
    //             if (postData.descriptions != null) {
    //               if (postData.descriptions.en != null) {
    //                 localeDescriptions = postData.descriptions.en;
    //               }
    //             }
    //             if (postData.user.userOrgNames != null) {
    //               if (postData.user.userOrgNames.en != null) {
    //                 localeOrgName = postData.user.userOrgNames.en;
    //               }
    //             }
    //             if (postData.user.userOrgURLs != null) {
    //               if (postData.user.userOrgURLs.en != null) {
    //                 localeUserOrgURL = postData.user.userOrgURLs.en;
    //               }
    //             }
    //             let refundPolicy = ""
    //             if (postData.refundPolicy != null){
    //               refundPolicy = postData.refundPolicy
    //             }
    //             setEventDetailsInfo({
    //               title: localeTitle,
    //               descriptions: localeDescriptions,
    //               orgName: localeOrgName,
    //               orgURL: localeUserOrgURL,
    //               language: lang,
    //               refundPolicy: refundPolicy,
    //             });
    //           } else {
    //             //nothing
    //             if (postData.titles != null) {
    //               if (postData.titles.zh != null) {
    //                 localeTitle = postData.titles.zh;
    //               }
    //             }
    //             if (postData.descriptions != null) {
    //               if (postData.descriptions.zh != null) {
    //                 localeDescriptions = postData.descriptions.zh;
    //               }
    //             }
    //             if (postData.user.userOrgNames != null) {
    //               if (postData.user.userOrgNames.zh != null) {
    //                 localeOrgName = postData.user.userOrgNames.zh;
    //               }
    //             }
    //             if (postData.user.userOrgURLs != null) {
    //               if (postData.user.userOrgURLs.zh != null) {
    //                 localeUserOrgURL = postData.user.userOrgURLs.zh;
    //               }
    //             }
    //             let refundPolicy = ""
    //             if (postData.refundPolicy != null){
    //               refundPolicy = postData.refundPolicy
    //             }
    //             setEventDetailsInfo({
    //               title: localeTitle,
    //               descriptions: localeDescriptions,
    //               orgName: localeOrgName,
    //               orgURL: localeUserOrgURL,
    //               language: lang,
    //               refundPolicy: refundPolicy,
    //             });
    //           }
    //         }
    //       } else {
    //         //nothing
    //         if (postData.titles != null) {
    //           if (postData.titles.zh != null) {
    //             localeTitle = postData.titles.zh;
    //           }
    //         }
    //         if (postData.descriptions != null) {
    //           if (postData.descriptions.zh != null) {
    //             localeDescriptions = postData.descriptions.zh;
    //           }
    //         }
    //         if (postData.user.userOrgNames != null) {
    //           if (postData.user.userOrgNames.zh != null) {
    //             localeOrgName = postData.user.userOrgNames.zh;
    //           }
    //         }
    //         if (postData.user.userOrgURLs != null) {
    //           if (postData.user.userOrgURLs.zh != null) {
    //             localeUserOrgURL = postData.user.userOrgURLs.zh;
    //           }
    //         }
    //         let refundPolicy = ""
    //         if (postData.refundPolicy != null){
    //               refundPolicy = postData.refundPolicy
    //         }
    //         setEventDetailsInfo({
    //           title: localeTitle,
    //           descriptions: localeDescriptions,
    //           orgName: localeOrgName,
    //           orgURL: localeUserOrgURL,
    //           language: lang,
    //           refundPolicy: refundPolicy
    //         });
    //       }

    //       if (postData.priceType != null) {
    //         if (postData.priceType == "CREDIT" || postData.priceType == "EMT") {
    //           setEventPriceOptions({
    //             price: postData.price,
    //             priceCurrency: postData.priceCurrency,
    //             priceEMTEmail: postData.priceEMTEmail,
    //             priceId: postData.priceId,
    //             priceOriginal: postData.priceOriginal,
    //             priceType: postData.priceType,
    //           });
    //         }
    //       }

    //       const viewByData = postData.viewBy;
    //       const viewCount = postData.viewCount;
    //       var ua = window.navigator.userAgent.toLowerCase();

    //       const timestampNow = firebase.firestore.Timestamp.fromDate(
    //         new Date()
    //       );
    //       const platformName = "web";
    //       let deviceName = "";
    //       if (isAndroid) {
    //         deviceName = "android";
    //       } else if (isIOS) {
    //         deviceName = "ios";
    //       } else {
    //         deviceName = "web";
    //       }
    //       let fuid = "";
    //       if (auth.currentUser !== null) {
    //         fuid = auth.currentUser.uid;
    //       }
    //       var viewLang = i18n.language
    //       const viewData = {
    //         fromUA: ua,
    //         fromUID: fuid,
    //         platform: platformName,
    //         device: deviceName,
    //         lang: viewLang,
    //         url: window.location.href,
    //         timestamp: timestampNow,
    //       };

    //       fires.collection("events").doc(id).collection("views").add(viewData);
    //         checkTicketStatus()
    //       if (currentUser !== null) {
    //         analytics.logEvent("view_item", {
    //           content_type: "image",
    //           content_id: id,
    //           items: [{ name: eventInfo.title, userUID: currentUser.uid }],
    //         });
    //         fires
    //           .collection("users")
    //           .doc(currentUser.uid)
    //           .get()
    //           .then((snapshotB) => {
    //             if (snapshotB.exists) {
    //               const userDataB = snapshotB.data()
    //               var fName = "";
    //               var lName = "";
    //               if (userDataB.firstName != null){
    //                 fName = userDataB.firstName
    //               }
    //               if (userDataB.lastName != null){
    //                 lName = userDataB.lastName
    //               }
    //               setUserInfo({ ...userDataB, firstName: fName, lastName: lName });
    //               if (userDataB.email != null && userDataB.email != ""){

    //                 fires
    //           .collection("events")
    //           .doc(id)
    //           .collection("members")
    //           .doc(userDataB.email)
    //           .get()
    //           .then((snapshotB) => {
    //             if (snapshotB.exists) {
    //               const uEmail = snapshotB.id;
    //               const uData = snapshotB.data();
    //               if (uData.invitedMembers != null) {
    //                 setInvitedMembers(uData.invitedMembers);
    //               }
    //               if (uData.profileURL != null){
    //                 const uProfileU = uData.profileURL
    //                 setSelectedImageURL(uProfileU)
    //               }
    //               var checkinTick = []
    //               if (uData.checkinTickets != null){
    //                 checkinTick = uData.checkinTickets
    //               }
    //               var tickets = []
    //               if (uData.tickets != null){
    //                 tickets = uData.tickets
    //               }
    //               setMemberMeInfo({ ...uData, checkinTickets: checkinTick, tickets: tickets });
    //             }
    //           });

    //               }
    //             }
    //           });
              
            
    //       } else {
    //         analytics.logEvent("view_item", {
    //           content_type: "event",
    //           content_id: id,
    //           items: [{ name: eventInfo.title, userUID: "" }],
    //         });
    //       }

    //       fires
    //         .collection("events")
    //         .doc(id)
    //         .collection("messages")
    //         .where("approved", "==", true)
    //         .orderBy("timestamp", "desc")
    //         .get()
    //         .then((snapshotC) => {
    //           const messageItems = [];
    //           snapshotC.docs.map((messageItm) => {
    //             //message 留言
    //             const messageId = messageItm.id;
    //             const messageData = messageItm.data();
    //             messageItems.push({ ...messageData, id: messageId });
    //           });
    //           setEventMessages(messageItems);
    //         });

    //       if (postData.length === 0) {
    //         posts.push({
    //           id: id,
    //           error: "NoAccess",
    //           error_message: "没有访问权限",
    //           error_code: 404,
    //         });
    //         setPosts(posts);
    //         document.title = "没有访问权限";
    //         document
    //           .querySelector('meta[name="description"]')
    //           .setAttribute("content", "请您查看是否具有访问权限");
    //         document.description = "请您查看是否具有访问权限";
    //       } else {
    //         if (postData.agenda !== null) {
    //           const agendas = [];
    //           postData.agenda.forEach((agendaItm) => {
    //             agendas.push(agendaItm);
    //           });
    //           setAgendas(agendas);
    //         }
    //         if (postData.sponsor !== null) {
    //           const sponsors = [];
    //           postData.sponsor.forEach((sponsorItm) => {
    //             sponsors.push(sponsorItm);
    //           });
    //           setSponsors(sponsors);
    //         }

    //         posts.push({ ...postData, id: id });
    //         setPosts(posts);
    //         document.title = postData.title;
    //         document
    //           .querySelector('meta[name="description"]')
    //           .setAttribute("content", postData.description);

    //         if (postData.photoURL !== "") {
    //           let link = document.querySelector("link[rel~='icon']");
    //           if (!link) {
    //             link = document.createElement("link");
    //             link.rel = "icon";
    //             document.getElementsByTagName("head")[0].appendChild(link);
    //           }
    //           link.href = postData.photoURL;
    //         }
    //       }
    //     } else {
    //       // Deleted
    //       window.location.href = "/";
    //     }
    //   });
  }, []);

  const DetailsPane = (post) => {
    return <ListGroup className="border-0" style={{ marginTop: "25px" }}>
    <ListGroup.Item 
    className="align-items-start">
      <div
        style={{
          fontSize: "17px",
          fontWeight: "bold",
        }}
      >
        {" "}
        <FaCalendarAlt size={20}/>{" "}
        {t("event_details_heading_basic_info_time")}
      </div>
      <div
        style={{
          marginTop: "5px",
          fontSize: "17px",
          fontWeight: "bold",
        }}
      >
        {eventIsTour() ? "Join event to select date" : (post.startDate.toDate().toDateString() ==
        post.endDate.toDate().toDateString()
          ? post.startDate.toDate().toDateString()
          : post.startDate.toDate().toDateString() +
            " - " +
            post.endDate.toDate().toDateString())}
      </div>
      <div
        style={{
          marginTop: "0px",
          fontSize: "17px",
          fontWeight: "bold",
        }}
        hidden={eventIsTour()}
      >
        {post.startDate
          .toDate()
          .toTimeString()
          .substring(0, 5)}{" "}
        -{" "}
        {post.endDate
          .toDate()
          .toTimeString()
          .substring(0, 5)}
      </div>
    </ListGroup.Item>
    <ListGroup.Item className="align-items-start">
      <div
        style={{
          fontSize: "17px",
          fontWeight: "bold",
        }}
      >
        <FaLandmark size={20} />{" "}
        {t("event_details_heading_basic_info_location")}
      </div>
      <div
        style={{
          marginTop: "5px",
          fontSize: "17px",
          fontWeight: "bold",
        }}
      >
        {post.address.online ? "" : post.address.name}
      </div>
      <div
        style={{
          marginTop: "5px",
          fontSize: "17px",
          fontWeight: "bold",
        }}
      >
        {post.address.online
          ? t(
              "event_details_heading_basic_info_label_online_event"
            )
          : post.address.fullAddress}
      </div>
      <Card.Link
        hidden={post.address.online}
        onClick={() => {
          if (post.address.online) {
            if (memberMeInfo.email != "") {
              window.location.href = post.liveURL;
            }
          } else {
            const googleMapStr =
              "https://google.com/maps/place/";
            const locationQuery =
              post.address.fullAddress.replace(
                " ",
                "+"
              );
            window.location.href =
              googleMapStr + locationQuery;
          }
        }}
      >
        {post.address.online
          ? t(
              "event_details_heading_basic_info_online_event"
            )
          : t(
              "event_details_heading_basic_info_navigation"
            )}
      </Card.Link>
    </ListGroup.Item>
    <ListGroup.Item
      className="align-items-start"
      hidden={
        eventInfo.status == "private" ||
        eventInfo.status == "ended" || 
        memberMeInfo.email == "" ||
        eventIsTour()
      }
    >
      <div
        style={{
          fontSize: "15px",
          fontWeight: "normal",
        }}
      >
        <FaGlassCheers />{" "}
        {t("event_details_heading_basic_info_people")}
      </div>
      <div
        style={{
          marginTop: "5px",
          fontSize: "17px",
          fontWeight: "bold",
        }}
      >
        {memberMeInfo.ticketId == "" ? t("event_details_heading_basic_info_label_firstcomefirstserve") : ''}
      </div>
      {
        memberMeInfo.tickets.map((itm) => {
          return <div><Card.Link
          href={"https://tickets.evtgo.com/ticket-id/"+itm}
          style={{
            marginTop: "5px",
            fontSize: "17px",
            fontWeight: "bold",
            color: 'black',
          }}
        >{itm}</Card.Link></div>
        })
      }
      <QRCodeCanvas
        id="qrCodeElement"
                                    style={{marginTop: '30px'}}
                                    value={
                                      "https://tickets.evtgo.com/ticket-id/" + memberMeInfo.ticketId
                                    }
                                    size={128}
                                    bgColor={"#ffffff"}
                                    fgColor={"#000000"}
                                    level={"L"}
                                    includeMargin={false}
                                    
                                  />
    </ListGroup.Item>
  </ListGroup>
  }

  async function handleWebUserRegister(e){
    e.preventDefault();
    const firstN = e.target[0].value
    const lastN = e.target[1].value
    const emailA = e.target[2].value.toLowerCase()
    const passwordA = e.target[3].value
    const passwordB = e.target[4].value  

    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

    if (passwordA == passwordB && passwordA != ""){
      try {
        setError("");
        setLoading(true);
        await signup(emailA, passwordA)
        const uid = auth.currentUser.uid
        if (!(await fires.collection('users').doc(uid).get()).exists){
          fires
          .collection('users')
          .doc(uid).set({
            userName: firstN + " " + lastN,
            name: firstN + " " + lastN,
            firstName: firstN,
            lastName: lastN,
            work: '',
            wechat: '',
            email: emailA,
            lang: 'en',
            currentEvent: id,
            timestamp: timestampNow,
          }).then(()=>{
            const uName = firstN + " " + lastN
            if (auth.currentUser != null){
              createSystemLoginNotification(auth.currentUser.uid)
            }
            setShowLoginRegister(false)
            window.location.reload()
          })
        }
        
      } catch {
        window.alert("Signup failed, user already exist, please try again")
        setError("Signup failed, user already exist, please try again");
      }
    }
    else{
      window.alert("Signup failed, password not matched")
      setError("Signup failed, password not matched")
    }
  }

  async function handleWebUserLogin(e){
    e.preventDefault();
    
    const emailA = e.target[0].value.toLowerCase()
    const passwordA = e.target[1].value
    
    try {
      setError("");
      setLoading(true);
      await login(emailA, passwordA);
      if (auth.currentUser != null){
        createSystemLoginNotification(auth.currentUser.uid)
      }
      setShowLoginRegister(false)
      window.location.reload()
    } catch {
      window.alert("login failed, please try again")
      setError("login failed, please try again");
    }
  }


  const WebLoginModal = () => {
    return <Modal
    show={showLoginRegister}
    onHide={() => {
      setShowLoginRegister(false);
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>
        {"Login / Register"}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div
        className="justify-content-center align-items-center"
        style={{
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
          <ButtonGroup className="align-items-center justify-content-center">
          <Button
            style={{width: '120px'}}
            variant={loginCode == 0 ? "dark" : "outline-dark"}
            onClick={() => {
              setLoginCode(0)    
            }}
            >
                          {'Login'}
                        </Button>
                        <Button
                        style={{width: '120px'}}
                          variant={loginCode == 1 ? "dark" : "outline-dark"}
                          onClick={() => {
                            setLoginCode(1)
                          }}
                        >
                          {'Register'}
                        </Button>              
          </ButtonGroup>
          
          </div>

          <Form onSubmit={handleWebUserLogin} hidden={loginCode == 1} style={{}}>
                <Form.Group id="email" style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'Email Address'}>
                  <Form.Control type="email" required />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group id="password" style={{ marginTop: "15px" }}>
                  <FloatingLabel label="Password">
                  <Form.Control type="password" required />
                  </FloatingLabel>
                </Form.Group>
                <Button
                  
                  className="w-100"
                  type="submit"
                  style={{ 
                    marginTop: "15px", 
                    borderColor: 'transparent',
                    backgroundColor: 'black' }}
                >
                  Login
                </Button>
                <div className="d-flex align-items-center justify-content-center" style={{marginTop: '15px'}}>
                <Card.Link href="/forgot-password">Forget password?</Card.Link>
                </div>
              </Form>

              <Form onSubmit={handleWebUserRegister} hidden={loginCode == 0} style={{}}>
              <Row md={2}>
              <Form.Group
                  id="first-name"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'First Name'}>
                  <Form.Control
                    type="text"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  id="last-name"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'Last Name'}>
                  <Form.Control
                    type="text"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

              </Row>
              

                <Form.Group
                  id="email"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'Email Address'}>
                  <Form.Control
                    type="text"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  id="password"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'Enter Password'}>
                  <Form.Control
                    type="password"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  id="repeat-password"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'Repeat Password'}>
                  <Form.Control
                    type="password"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

                
                <Button
                  disabled={loading}
                  className="w-100"
                  type="submit"
                  style={{ 
                    marginTop: "15px", 
                    borderColor: 'transparent',
                    backgroundColor: 'black' }}
                >
                  Register
                </Button>
              </Form>



        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
          
                
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="secondary"
        onClick={() => {
          
        }}
      >
        {'OK'}
      </Button>
    </Modal.Footer>
  </Modal>

  }

  const LeaveMessageModal = () => {
    return <Modal
    show={showLeaveMessage}
    onHide={() => {
      setShowLeaveMessage(false);
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>
        {t("event_details_tab_message")}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div
        className="justify-content-center align-items-center"
        style={{
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
          <Form onSubmit={(e)=>{
            e.preventDefault()
            const messageItm = e.target[0].value
            if (messageItm != null && messageItm != ""){
              let timestampNow =
                              firebase.firestore.Timestamp.fromDate(new Date());
              const messageObject = {
                "name":memberMeInfo.name,
                "message":messageItm,
                "profileURL":memberMeInfo.profileURL,
                "timestamp": timestampNow,
                "approved":true,
                "status":"",
              }
              fires.collection("events").doc(id).collection("messages").add(messageObject).then((docRef)=>{
                setShowLeaveMessage(false);
                window.location.reload()
              })
              
              
            }
          }} style={{}}>
                <Form.Group id="text" style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={t("event_details_tab_message")}>
                  <Form.Control as="textarea" rows={10} required />
                  </FloatingLabel>
                </Form.Group>
                <Button
                  className="w-100"
                  type="submit"
                  style={{ 
                    marginTop: "15px", 
                    borderColor: 'transparent',
                    backgroundColor: 'black' }}
                >
                  {t("event_details_tab_message")}
                </Button>

              </Form>

        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
            
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="secondary"
        onClick={() => {
          setShowLeaveMessage(false);
        }}
      >
        {'OK'}
      </Button>
    </Modal.Footer>
  </Modal>

  }


  const WebSidePane = (post) => {
    return <div
    hidden={isMobile}
    style={{
      marginTop: "0px",
      width: isMobile ? "0%" : "30%",
    }}
  >
    {
      DetailsPane(post)
    }
  </div>
  }

  const MobileSidePane = (post) => {
    return <div
    hidden={!isMobile}
    style={{
      width: isMobile ? "100%" : "0%",
    }}
  >
    {
      DetailsPane(post)
    }
  </div>
  }

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  return (
    <div>
      {posts.map((post) => {
        const errorA = post.error;

        const postTopics = post.topics;
        const viewByData = post.viewBy;

        if (errorA === "NotFound" || errorA === "NoAccess") {
          return (
            <Container
              className="d-flex align-items-center justify-content-center"
              style={{ minHeight: "100vh" }}
            >
              <div className="w-100" style={{ maxWidth: maxW }}>
                <Card>
                  <Helmet>
                    <title>无法显示</title>
                    <meta name="description" content="无法显示" />
                  </Helmet>
                  <Card.Body>
                    <Card.Title>404</Card.Title>
                    <Card.Text>该用户已删除或内容不存在</Card.Text>
                    <Card.Text>
                      User deleted or the post doesn't exist
                    </Card.Text>
                    <Card.Link href="/">返回首页</Card.Link>
                  </Card.Body>
                </Card>
                ;
              </div>
            </Container>
          );
        } else {
          return (
            <>
              <Helmet>
                <title>{eventDetailsInfo.title}</title>
                <meta
                  name="description"
                  content={eventDetailsInfo.descriptions[0]}
                />
                <meta name="twitter:card" content={post.photoURL} />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content={eventDetailsInfo.title} />
                <meta
                  name="twitter:description"
                  content={eventDetailsInfo.descriptions[0]}
                />
                <meta name="twitter:image" content={post.photoURL} />
                <meta property="og:title" content={eventDetailsInfo.title} />
                <meta
                  property="og:description"
                  content={eventDetailsInfo.descriptions[0]}
                />
                <meta property="og:image" content={post.photoURL} />
                <meta
                  property="og:url"
                  content={window.location.href}
                />
                <meta
                  property="og:site_name"
                  content={eventDetailsInfo.title}
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
              </Helmet>
              <Container
                className="d-flex justify-content-center"
                style={{ minHeight: "100vh", minWidth: "100vw",}}
              >
                <div
                  className="w-100"
                  style={{
                    maxWidth: maxW,
                    marginTop: "25px",
                    marginBottom: "25px",
                    
                  }}
                >
                  <div>

                    
                    {
                      LeaveMessageModal()
                    }
                  

                    <Card className="border-0" style={{}}>
                    


                    <div style={{padding: '15px'}}>
                  <Card.Text
                              style={{
                                marginTop: "5px",
                                fontSize: "17px",
                                fontWeight: "bold",
                                marginBottom: "5px",
                              }}
                            >
                              {post.startDate.toDate().toLocaleDateString("zh")}
                            </Card.Text>
                            <Card.Title
                              style={{ fontSize: "30px", fontWeight: "bold" }}
                            >
                              {eventDetailsInfo.title}
                            </Card.Title>
                            <Card.Text>
                              {t("event_details_support_publish_at")}{" "}
                              {post.timestamp.toDate().toLocaleDateString("zh")}
                            </Card.Text>

                            <DropdownButton
                              title={"中文/ENGLISH"}
                              id="dropdown-menu-align-right"
                              variant="outline-dark"
                
                              style={{ fontWeight: "bold" }}
                              onSelect={(e) => {
                                selectLanguage(e)
                              }}
                            >
                              {lngs.map((lng, i) => {
                                const { code, native } = lng;
                                return (
                                  <Dropdown.Item
                                    eventKey={code}
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {native}
                                  </Dropdown.Item>
                                );
                              })}
                              <Dropdown.Divider />
                              <Dropdown.Item
                                eventKey="more-language"
                                style={{ color: "black", fontWeight: "bold" }}
                              >
                                More Languages
                              </Dropdown.Item>
                            </DropdownButton>

                    </div>


                      
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: isMobile ? "100%" : "70%",
                          }}
                        >
                          <Card.Body>

                          

                          <Card.Img
                                variant="top"
                                src={post.photoURL}
                                style={{  }}
                                />

                          <div hidden={videoURL == ""}>
                          
                          <ReactPlayer 
                          url={videoURL} 
                          style={{maxWidth: '100%', marginTop: '45px'}} 
                          playing={false} 
                          muted={true} 
                          controls/>
                          </div>
                          

                                {
                                  MobileSidePane(post)
                                }

                                

                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  style={{
                                    marginTop: "25px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                    }}
                                  >
                                    {t("event_details_heading_details_info")}
                                  </Card.Text>
                                </div>
                                {eventDetailsInfo.descriptions.map(
                                  (description) => {
                                    if (isValidHttpUrl(description)){
                                      return (
                                        <Card.Link
                                          href={description}
                                          style={{
                                            marginTop: "15px",
                                            fontSize: isMobile ? "15px" : "15px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          {description}
                                        </Card.Link>
                                      );
                                    }
                                    else{
                                      if (description.startsWith("<b>")){
                                        let desc = description.replace('<b>','')
                                        return (
                                          <Card.Text
                                            style={{
                                              marginTop: "15px",
                                              fontSize: isMobile ? "17px" : "17px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {desc}
                                          </Card.Text>
                                        );
                                      }
                                      else{
                                        return (
                                          <Card.Text
                                            style={{
                                              marginTop: "15px",
                                              fontSize: isMobile ? "15px" : "15px",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {description}
                                          </Card.Text>
                                        );
                                      }
                                    }
                                    
                                  }
                                )}

                                {images.map((imageURL) => {
                                  return (
                                    <Card.Img
                                      variant="top"
                                      style={{
                                        width: "100%",
                                        marginTop: "15px",
                                        marginBottom: "15px",
                                      }}
                                      src={imageURL}
                                    />
                                  );
                                })}

                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  style={{
                                    marginTop: "25px",
                                    marginBottom: "20px",
                                  }}
                                  hidden={post.liveURL == ""}
                                >
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                    }}
                                    hidden={post.liveURL == ""}
                                  >
                                    {t("event_details_heading_live_info")}
                                  </Card.Text>
                                </div>

                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  style={{
                                    marginTop: "25px",
                                    marginBottom: "20px",
                                  }}
                                  hidden={post.ticketURL == ""}
                                >
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                    }}
                                    hidden={post.ticketURL == ""}
                                  >
                                    {t("event_details_heading_ticket_info")}
                                  </Card.Text>
                                </div>

                                <Card.Link
                                  style={{
                                    marginTop: "25px",
                                    fontSize: "20px",
                                    fontWeight: "normal",
                                    color: "black",
                                  }}
                                  href={post.ticketURL}
                                >
                                  {post.ticketURL}
                                </Card.Link>

                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  style={{
                                    marginTop: "25px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Card.Text
                                    className="text-center"
                                    hidden={eventOptions.hidehost}
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                    }}
                                  >
                                    {t("event_details_heading_host_info")}
                                  </Card.Text>
                                </div>

                                <ListGroup.Item
                                  className="d-flex align-items-center"
                                  hidden={eventOptions.hidehost}
                                  style={{
                                    alignSelf: "center",
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "5px",
                                  }}
                                  onClick={() => {
                                    if (eventDetailsInfo.orgURL != "") {
                                      window.location.href =
                                        eventDetailsInfo.orgURL;
                                    } else {
                                      window.location.href =
                                        "/organizer/" + post.orgId;
                                    }
                                  }}
                                >
                                  <Image
                                    roundedCircle
                                    hidden={eventOptions.hidehost}
                                    src={
                                      post.user.imageURL == ""
                                        ? profileString
                                        : post.user.imageURL
                                    }
                                    width="50px"
                                    height="50px"
                                  />
                                  <div style={{ marginLeft: "5px" }}>
                                    <Card.Text
                                      hidden={eventOptions.hidehost}
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        marginBottom: "0px",
                                        color: "black",
                                      }}
                                    >
                                      {eventDetailsInfo.orgName}
                                    </Card.Text>
                                    <Card.Text
                                      hidden={eventOptions.hidehost}
                                      className="text-secondary"
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {t("event_details_support_host_info")}
                                    </Card.Text>
                                  </div>
                                </ListGroup.Item>

                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  style={{
                                    marginTop: "25px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Card.Text
                                    className="text-center"
                                    hidden={cohosts.length == 0}
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                    }}
                                  >
                                    {t("event_details_heading_cohost_info")}
                                  </Card.Text>
                                </div>

                                <ListGroup className="border-0">
                                  {
                                    cohosts.map((hostInfo)=>{
                                      return <ListGroup.Item
                                      className="d-flex align-items-center border-0"
                                      style={{
                                        alignSelf: "center",
                                        display: "flex",
                                        width: "100%",
                                        marginBottom: "5px",
                                      }}
                                      onClick={()=>{
                                        window.location.href = hostInfo.url
                                      }}
                                      
                                    >
                                      <Image
                                        roundedCircle
                                        src={
                                          hostInfo.profileURL
                                        }
                                        width="50px"
                                        height="50px"
                                      />
                                      <div style={{ marginLeft: "5px" }}>
                                        <Card.Text
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "bold",
                                            marginBottom: "0px",
                                            color: "black",
                                          }}
                                        >
                                          {hostInfo.name}
                                        </Card.Text>
                                        <Card.Text
                                          className="text-secondary"
                                          style={{
                                            fontSize: "15px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          {t("event_details_support_host_info")}
                                        </Card.Text>
                                      </div>
                                    </ListGroup.Item>
                                    })
                                  }
                                </ListGroup>


                                {/* Agenda */}
                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  style={{
                                    marginTop: "25px",
                                    marginBottom: "10px",
                                  }}
                                  hidden={true}
                                >
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                    }}
                                    hidden={true}
                                  >
                                    {t("event_details_heading_agenda_info")}
                                  </Card.Text>
                                </div>
                                <Card.Text
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    color: "#000000",
                                    marginTop: "10px",
                                  }}
                                  hidden={true}
                                >
                                  {t("event_details_support_no_agenda")}
                                </Card.Text>

                                <div style={{ marginTop: "25px" }}>
                                  {agendas.map((agendaItm) => {
                                    return (
                                      <Card
                                        className="align-items-start border-0"
                                        style={{ marginBottom: "15px", padding: "15px" }}
                                        hidden={true}
                                      >
                                        <Card.Title
                                          style={{
                                            fontSize: "17px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {agendaItm.title}
                                        </Card.Title>
                                        <br />
                                        <Card.Text
                                          style={{
                                            fontSize: "15px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          {agendaItm.description}
                                        </Card.Text>
                                        <div>
                                          <FaGlobeAmericas />
                                          {agendaItm.location !== ""
                                            ? "  " + agendaItm.location
                                            : "  no location"}
                                        </div>
                                        <div>
                                          <FaClock />
                                          {"  " +
                                            agendaItm.date
                                              .toDate()
                                              .toLocaleString("zh")}
                                        </div>
                                        <br />
                                      </Card>
                                    );
                                  })}
                                </div>


                                {/* sponsors */}

                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  style={{
                                    marginTop: "25px",
                                    marginBottom: "10px",
                                  }}
                                  hidden={true}
                                >
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                    }}
                                    hidden={true}
                                  >
                                    {t("event_details_heading_sponsor_info")}
                                  </Card.Text>
                                </div>

                                <Card.Text
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    color: "#000000",
                                    marginTop: "10px",
                                  }}
                                  hidden={true}
                                >
                                  {t("event_details_support_no_sponsor")}
                                </Card.Text>

                                {sponsors.map((sponsorItm) => {
                                  return (
                                    <Card
                                      className="border-0 justify-content-center"
                                      style={{
                                        margin: "25px",
                                        backgroundColor: "#ffffff",
                                        borderRadius: "15px",
                                      }}
                                      hidden={true}
                                    >
                                      <Card.Img
                                        variant="top"
                                        style={{ maxHeight: isMobile ? "250px" : "600px" }}
                                        src={sponsorItm.imageURL}
                                      />
                                      <Card.Title
                                        className="text-center"
                                        style={{
                                          margin: "15px",
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {sponsorItm.name}
                                      </Card.Title>
                                      <Card.Text
                                        className="text-center"
                                        style={{
                                          marginLeft: "15px",
                                          marginRight: "15px",
                                          fontSize: "15px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {sponsorItm.description.substring(
                                          0,
                                          100
                                        )}
                                      </Card.Text>
                                      <div
                                        className="justify-content-center align-items-center"
                                        style={{ alignSelf: "center" }}
                                      >
                                        <Button
                                          style={{
                                            backgroundColor: "transparent",
                                            borderRadius: "25px",
                                            borderColor: "#000000",
                                            borderWidth: "2px",
                                            fontSize: "20px",
                                            fontWeight: "normal",
                                            height: "50px",
                                            marginTop: "25px",
                                            marginBottom: "15px",
                                            color: "black",
                                            width: "200px",
                                          }}
                                          onClick={() => {
                                            createLinkVisitLog(sponsorItm.url,sponsorItm.name)
                                            window.location.href =
                                              sponsorItm.url;
                                          }}
                                        >
                                          {t("event_details_action_link")}
                                        </Button>
                                      </div>
                                    </Card>
                                  );
                                })}



                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  hidden={eventInfo.status == 'private' || eventIsTour()}
                                  style={{
                                    marginTop: "25px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                    }}
                                    hidden={eventIsTour()}
                                  >
                                    {t("event_details_heading_payment_info")}
                                  </Card.Text>
                                </div>

                                <ListGroup.Item
                                  className="align-items-start"
                                  controlId="price-item"
                                  hidden={eventInfo.status == 'private' || eventIsTour()}
                                  style={{ marginTop: "15px" }}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="fw-normal">
                                      {t(
                                        "event_details_registration_header_event_type"
                                      )}
                                    </div>
                                    <div className="fw-bold">
                                      {eventPriceOptions.priceType == ""
                                        ? t(
                                            "event_details_registration_header_free_event"
                                          )
                                        : eventPriceOptions.priceType == "FREE"
                                        ? t(
                                            "event_details_registration_header_free_event"
                                          )
                                        : t(
                                            "event_details_registration_header_paid_event"
                                          )}
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="fw-normal">
                                      {t(
                                        "event_details_registration_header_payment_type"
                                      )}
                                    </div>
                                    <div className="fw-bold">
                                      {eventPriceOptions.priceType == "EMT"
                                        ? "E-Transfer"
                                        : eventPriceOptions.priceType == "FREE"
                                        ? t(
                                            "event_details_registration_header_free_event"
                                          )
                                        : eventPriceOptions.priceType == ""
                                        ? t(
                                            "event_details_registration_header_free_event"
                                          )
                                        : t(
                                            "event_details_registration_header_credit"
                                          )}
                                    </div>
                                  </div>
                                  <br />
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="fw-bold">
                                      {t(
                                        "event_details_registration_header_total_price"
                                      )}
                                    </div>
                                    <div className="fw-bold">
                                      {eventPriceOptions.price == 0
                                        ? t(
                                            "event_details_registration_header_free"
                                          )
                                        : "$" +
                                          eventPriceOptions.price +
                                          eventPriceOptions.priceCurrency}
                                    </div>
                                  </div>
                                </ListGroup.Item>

                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  hidden={eventInfo.status == 'private'}
                                  style={{
                                    marginTop: "25px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                    }}
                                  >
                                    {t("event_details_heading_refund_info")}
                                  </Card.Text>
                                </div>
                          
                                <div>
                                  <Card.Text
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "normal",
                                      color: "#000000",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {eventDetailsInfo.refundPolicy}
                                  </Card.Text>
                                </div>


                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    marginTop: "25px",
                                    marginBottom: "25px",
                                  }}
                                >
                                  <Button
                                    id="event-register"
                                    variant="dark"
                                    hidden={true}
                                    style={{
                                      height: "60px",
                                      width: "200px",
                                      borderRadius: "30px",
                                      marginTop: "25px",
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      handleEventRegisteration();
                                    }}
                                  >
                                    {memberMeInfo.email == ""
                                      ? t("event_details_action_book_ticket")
                                      : memberMeInfo.email != "" &&
                                        !memberMeInfo.checkinStatus &&
                                        memberMeInfo.ticketId != null
                                      ? t(
                                          "event_details_action_complete_checkin"
                                        )
                                      : t("event_details_action_enter_event")}
                                  </Button>
                                </div>


                                

                                {/* attendees */}
{/* 
<div
                                  className="d-flex justify-content-between align-items-start"
                                  style={{
                                    marginTop: "25px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                    }}
                                  >
                                    {t("event_details_support_me")}
                                  </Card.Text>
                                  <div>
                                    <Button
                                      variant="dark"
                                      style={{ marginRight: "5px" }}
                                      hidden={
                                        eventInfo.status == "private" ||
                                        eventInfo == "ended" ||
                                        memberMeInfo.email == ""
                                      }
                                      onClick={() => {
                                        if (
                                          memberMeInfo.email != "" &&
                                          eventPriceOptions.priceType == "FREE"
                                        ) {
                                          setShowInvited(true);
                                        } else {
                                          window.alert(
                                            "You haven't register for the event"
                                          );
                                        }
                                      }}
                                    >
                                      {t("event_details_action_invitation")}
                                    </Button>

                                    <Button
                                      variant="dark"
                                      style={{ marginRight: "5px" }}
                                      hidden={
                                        eventInfo.status == "private" ||
                                        eventInfo == "ended" ||
                                        memberMeInfo.email == ""
                                      }
                                      onClick={() => {
                                        handleEventRegisteration();
                                      }}
                                    >
                                      {memberMeInfo.checkinStatus
                                        ? t("event_details_action_book_ticket")
                                        : t(
                                            "event_details_action_complete_checkin"
                                          )}
                                    </Button>
                                  </div>
                                </div>

                                <ListGroup style={{ marginTop: "15px" }}>
                                  <ListGroup.Item
                                    className="d-flex align-items-center"
                                    style={{
                                      alignSelf: "center",
                                      display: "flex",
                                      width: "100%",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <Image
                                      roundedCircle
                                      src={memberMeInfo.profileURL}
                                      width="50px"
                                      height="50px"
                                    />
                                    <div style={{ marginLeft: "5px" }}>
                                      <Card.Text
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "bold",
                                          marginBottom: "0px",
                                          color: "black",
                                        }}
                                      >
                                        {memberMeInfo.name}
                                      </Card.Text>
                                      <Card.Text
                                        className="text-secondary"
                                        style={{
                                          fontSize: "15px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {memberMeInfo.work}
                                      </Card.Text>
                                    </div>
                                  </ListGroup.Item>
                                </ListGroup>

                                
                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  style={{
                                    marginTop: "25px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <Card.Text
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "#000000",
                                }}
                                  
                                  hidden={memberMeInfo.tickets.length == 0}
                                >
                                  {t("event_details_support_me_ticket")}
                                </Card.Text>

                                </div>
                                <ListGroup style={{ marginTop: "15px" }}>
                                  {
                                    memberMeInfo.tickets.map((ticketIdd) => {
                                      return <ListGroup.Item>{ticketIdd}</ListGroup.Item>
                                    })
                                  }
                                </ListGroup>
                                

                                <Card.Text
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    color: "#000000",
                                  }}
                                  hidden={memberMeInfo.ticketId != ""}
                                >
                                  {t("event_details_support_require_login")}
                                </Card.Text>

                                <Card.Link hidden={userInfo.email != ""} onClick={()=>{
                                  window.location.href = "/login"
                                }}>{t('event_details_registration_header_login')}</Card.Link>


                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  style={{
                                    marginTop: "25px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                    }}
                                    hidden={invitedMembers.length == 0}
                                  >
                                    {t("event_details_support_invited")}
                                  </Card.Text>
                                </div>

                                <ListGroup style={{ marginTop: "15px" }}>
                                  {invitedMembers.map((memberItm) => {
                                    return (
                                      <ListGroup.Item
                                        className="d-flex align-items-center"
                                        style={{
                                          alignSelf: "center",
                                          display: "flex",
                                          width: "100%",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <Image
                                          roundedCircle
                                          src={profileString}
                                          width="50px"
                                          height="50px"
                                        />
                                        <div style={{ marginLeft: "5px" }}>
                                          <Card.Text
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: "bold",
                                              marginBottom: "0px",
                                              color: "black",
                                            }}
                                          >
                                            {memberItm.name}
                                          </Card.Text>
                                          <Card.Text
                                            className="text-secondary"
                                            style={{
                                              fontSize: "15px",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {memberItm.email}
                                          </Card.Text>
                                        </div>
                                      </ListGroup.Item>
                                    );
                                  })}
                                </ListGroup>

                                <div
                                  className="d-flex justify-content-between align-items-start"
                                  style={{
                                    marginTop: "25px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <Card.Text
                                    className="text-center"
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: "#000000",
                                    }}
                                    hidden={memberMeInfo.email == ""}
                                  >
                                    {t("event_details_support_modify_info")}
                                  </Card.Text>
                                </div>

                                <Form onSubmit={handleInfoSubmit} hidden={memberMeInfo.email == ""}>
                              <Form.Group
                                id="email"
                                style={{ marginTop: "15px" }}
                              >
                                <FloatingLabel
                                label={t('user_details_user_info_email')}>
                                <Form.Control
                                  type="email"
                                  defaultValue={memberMeInfo.email}
                                  required
                                  disabled
                                />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group
                                id="name"
                                style={{ marginTop: "15px" }}
                              >
                                <FloatingLabel
                                label={t('user_details_user_info_name')}>
                                <Form.Control
                                  type="text"
                                  defaultValue={memberMeInfo.name}
                                  required
                                />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group
                                id="work"
                                style={{ marginTop: "15px" }}
                              >
                                <FloatingLabel
                                label={t('user_details_user_info_work')}>
                                <Form.Control
                                  type="text"
                                  defaultValue={memberMeInfo.work}
                                />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group 
                              id="user-profile" 
                              style={{
                                marginTop: '15px', 
                                marginBottom: '15px'}}>
                            <Form.Label 
                            style={{
                              fontWeight: 'bold'
                              }}>{t('user_details_user_info_choose_pic')}</Form.Label>
                            {selectedImage && (
                              <div>
                              <img 
                              alt="not found" 
                              width={"250px"} 
                              src={URL.createObjectURL(selectedImage)} 
                              />
                              <br />
                              <Alert 
                              variant="primary"
                              >{uploadS}
                              </Alert>
                              <div>
                                <button 
                                onClick={()=>setSelectedImage(null)}
                                >Remove</button>
                                <button 
                                onClick={handleImageSubmit}
                                >Upload</button>
                              </div>
                              </div>
      )}
      <br />
      <input
        type="file"
        name="coverImage"
        title="Choose Image"
        onChange={handleImageChange}
      />
      <br /><br />
      <div>
      <img alt="profile" width={"100px"} height={"100px"} src={selectedImageURL}/>
      </div>
        </Form.Group>
      
                              <Button
                                className="w-100"
                                type="submit"
                                style={{
                                  marginTop: "15px",
                                  color: "white",
                                  background: "black",
                                }}
                              >
                                {t('user_details_action_modify_user_info')}
                              </Button>
                            </Form> */}


                            {/* <Tabs
                              defaultActiveKey="info"
                              id="event-tab"
                              className="mb-3"
                              style={{ }}
                            >
                              <Tab
                                eventKey="info"
                                title={t("event_details_tab_basic_info")}
                              >
                              
                              </Tab>
                              <Tab
                                eventKey="agenda"
                                title={t("event_details_tab_agenda")}
                              >
                                
                              </Tab>
                              

                              <Tab
                                eventKey="sponsor"
                                title={t("event_details_tab_sponsor")}
                              >
                                
                              </Tab>

                              

                              <Tab
                                eventKey="attendent"
                                title={t("event_details_tab_attendent")}
                              >
                                

                              </Tab>
                              <Tab
                                eventKey="message1"
                                title={t("event_details_tab_message")}
                              >
                                <Card.Text
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    color: "#000000",
                                    marginTop: "10px",
                                  }}
                                  hidden={memberMeInfo.email != ""}
                                >
                                  {t("event_details_support_require_login")}
                                </Card.Text>
                                <Card.Link hidden={userInfo.email != ""} onClick={()=>{
                                  window.location.href = "/login"
                                }}>{t('event_details_registration_header_login')}</Card.Link>

                                <Button variant="dark" hidden={userInfo.email == ""} onClick={()=>{
                                  setShowLeaveMessage(true);
                                }}>{t("event_details_tab_message")}</Button>

                                <ListGroup style={{marginTop: "15px"}}>
                                  {eventMessages.map((messageItm) => {
                                    return (
                                      <ListGroup.Item
                                        className="d-flex align-items-center"
                                        style={{
                                          alignSelf: "center",
                                          display: "flex",
                                          width: "100%",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <Image
                                          roundedCircle
                                          src={
                                            messageItm.profileURL == ""
                                              ? profileString
                                              : messageItm.profileURL
                                          }
                                          width="50px"
                                          height="50px"
                                        />
                                        <div style={{ marginLeft: "5px" }}>
                                          <Card.Text
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: "bold",
                                              marginBottom: "0px",
                                              color: "black",
                                            }}
                                          >
                                            {messageItm.name}
                                          </Card.Text>
                                          <Card.Text
                                            className="text-secondary"
                                            style={{
                                              fontSize: "15px",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {messageItm.message}
                                          </Card.Text>
                                          <Card.Text
                                            className="text-secondary"
                                            style={{
                                              fontSize: "15px",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {t(
                                              "event_details_support_publish_at"
                                            )}{" "}
                                            {messageItm.timestamp
                                              .toDate()
                                              .toLocaleString("zh")}
                                          </Card.Text>
                                        </div>
                                      </ListGroup.Item>
                                    );
                                  })}
                                </ListGroup>
                                
                              </Tab>
                            </Tabs> */}

                          </Card.Body>
                        </div>

                        {
                          WebSidePane(post)
                        }
                        
                      </div>
                    </Card>
                  </div>
                </div>

                <Modal
                  show={showCompleted}
                  onHide={() => {
                    setShowCompleted(false);
                    window.location.reload();
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {t("event_details_success_header")}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div
                      className="justify-content-center align-items-center"
                      style={{
                        marginTop: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      <Card.Title className="text-center">
                        {t("event_details_success_header_notice")}
                      </Card.Title>
                      <Card.Text
                        className="text-center"
                        style={{ marginTop: "15px" }}
                      >
                        {t("event_details_success_header_email")}
                      </Card.Text>

                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          marginTop: "25px",
                          marginBottom: "25px",
                        }}
                      >
                        <Button variant="dark" onClick={()=>{
                          setShowCompleted(false);
                          setShowInvited(true)
                        }}>{t("event_details_heading_basic_info_invitation")}</Button>
                        </div>

                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          marginTop: "15px",
                          marginBottom: "15px",
                        }}
                      >
                        <AddToCalendarButton
                                name={eventDetailsInfo.title}
                                startDate={
                                  getEventDateString(
                                    post.startDate.toDate()
                                  )
                                }
                                endDate={
                                  getEventDateString(
                                    post.endDate.toDate()
                                  )
                                }
                                startTime={
                                  getEventTimeString(
                                  post.startDate.toDate()
                                )
                              }
                                endTime={
                                  getEventTimeString(
                                    post.endDate.toDate()
                                  )
                                }
                                language={i18n.language}
                                description={eventDetailsInfo.description}
                                location={post.address.fullAddress}
                                options={["Apple", "Google", "Yahoo", "iCal"]}
                                listStyle="modal"
                                iCalFileName="letjoy-event"
                              ></AddToCalendarButton>

                        
                              
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowCompleted(false);
                        if (lang == null) {
                          window.location.reload();
                        } else {
                          window.location.href = "/" + id + "/" + lang;
                        }
                      }}
                    >
                      {t("event_details_action_ok")}
                    </Button>
                  </Modal.Footer>
                </Modal>

                

                <Modal
                  show={showCheckIn}
                  onHide={() => {
                    setEventIDError("");
                    setEmailError("");
                    setShowCheckIn(false);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {t("event_details_checkin_header_checkin")}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form onSubmit={handleCheckInSubmit}>
                      <Form.Group className="mb-3" controlId="id.control">
                        <Form.Label>
                          {t("event_details_checkin_header_event_id")} *
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder={t(
                            "event_details_checkin_header_event_id_placeholder"
                          )}
                          defaultValue=""
                          autoFocus
                          onChange={(e) => {
                            e.target.value !== "" && post.pin === e.target.value
                              ? setEventIDError("")
                              : setEventIDError(
                                  i18n.language == "en"
                                    ? "Your checkin ID is incorrect"
                                    : "您输入的活动ID不正确"
                                );
                          }}
                        />
                        {eventIDError && (
                          <Alert variant="danger" style={{ marginTop: "10px" }}>
                            {eventIDError}
                          </Alert>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="email.control">
                        <Form.Label>
                          {t("event_details_checkin_header_email")} *
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder={t(
                            "event_details_checkin_header_email_placeholder"
                          )}
                          defaultValue={memberMeInfo.email}
                          onChange={(e) => {
                            memberMeInfo.email == e.target.value
                              ? setEmailError("")
                              : setEmailError(
                                  i18n.language == "en"
                                    ? "Couldn't find your email address"
                                    : "无法找到您的电子邮件"
                                );
                          }}
                        />
                        {emailError && (
                          <Alert variant="danger" style={{ marginTop: "10px" }}>
                            {emailError}
                          </Alert>
                        )}
                      </Form.Group>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        id="form-checkin"
                      >
                        <Button
                          variant="success"
                          type="submit"
                          style={{ width: "100%" }}
                        >
                          {t("event_details_checkin_header_action_checkin")}
                        </Button>
                      </div>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setEventIDError("");
                        setEmailError("");
                        setShowCheckIn(false);
                      }}
                    >
                      {t("event_details_action_close")}
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showInvited}
                  onHide={() => {
                    setShowInvited(false);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {t("event_details_heading_basic_info_invitation")}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group
                        id="invitee-email"
                        style={{ marginTop: "5px" }}
                      >
                        <Form.Label style={{ fontWeight: "bold" }}>
                          {t("event_details_invitation_email")} *
                        </Form.Label>
                        <Form.Control
                          type="email"
                          rows={1}
                          ref={eventInviteeEmailRef}
                          required
                        />
                      </Form.Group>
                      <Row md={2}>
                        <Col>
                          <Form.Group
                            id="invitee-fn"
                            style={{ marginTop: "5px" }}
                          >
                            <Form.Label style={{ fontWeight: "bold" }}>
                              {t("event_details_invitation_firstname")}
                            </Form.Label>
                            <Form.Control
                              type="name"
                              rows={1}
                              ref={eventInviteeFirstNameRef}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            id="invitee-ln"
                            style={{ marginTop: "5px" }}
                          >
                            <Form.Label style={{ fontWeight: "bold" }}>
                              {t("event_details_invitation_lastname")}
                            </Form.Label>
                            <Form.Control
                              type="name"
                              rows={1}
                              ref={eventInviteeLastNameRef}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group
                        className="d-flex justify-content-center align-items-center"
                        style={{ marginTop: "25px" }}
                      >
                        
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                </Modal>
              </Container>
              <Navbar
                bg="white"
                variant="white"
                expand="lg"
                sticky="bottom"
                style={{
                  maxHeight: isMobile ? "220px" : "100px",
                  //height: isMobile ? "200px" : "100px"
                }}
                  hidden={
                      eventInfo.status == "private" ||
                      eventInfo.status == "ended" ||
                      eventInfo.endDate.toDate() < (new Date())
                  }
              >
                <div className="align-items-start jusify-content-between" style={{padding: '10px', marginLeft: '15px', marginRight: '15px'}} hidden={!isMobile}>
                  <Card.Text style={{fontSize: 15, fontWeight: 'bold'}}>{eventInfo.title.substring()}</Card.Text>
                  <Card.Text style={{fontSize: 15, fontWeight: 'bold'}}>From ${eventInfo.price}</Card.Text>
                </div>
                <Container className="d-flex align-items-center jusify-content-center" 
                style={{
                  alignContent: 'center', 
                   alignItems: 'center', 
                   justifyContent: 'center'
                   }}>
                <Button
                    id="event"
                    className="border-0"
                    variant="dark"
                    style={{ 
                      height: '45px', 
                      borderRadius: '0px', 
                      marginBottom: '15px', 
                      width: isMobile ? window.innerWidth : window.innerWidth/2, 
                      fontSize: 17, 
                      fontWeight: 'bold',  
                      backgroundColor: "#f6AC19", 
                      color: 'black'}}
                    onClick={() => {
                      handleEventRegisteration();
                    }}
                    disabled={(eventOptions.max - totalTicketCount) <= 0 && eventOptions.max > 0}
                  >
                  {(eventOptions.max - totalTicketCount) <= 0 ? t("event_details_action_book_ticket_soldout") : t("event_details_action_book_ticket")} {eventOptions.max - totalTicketCount <= 0 ? '' : (eventOptions.max - totalTicketCount < 20 && eventOptions.max - totalTicketCount > 0) ? ` (${eventOptions.max - totalTicketCount} Left)` : ''}
                  </Button>
                </Container>
              </Navbar>
            </>
          );
        }
      })}
      <SimpleFooter/>
    </div>
  );
}
