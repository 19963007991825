import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Tab, Tabs, Row, Col, Container, TabContainer, ListGroup, Dropdown, DropdownButton, Carousel, Nav, NavDropdown, Navbar } from "react-bootstrap"
import { auth, fires} from "../firebase"
import { Link, useNavigate, Route, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect"
import firebase from "firebase/app";
import "firebase/firestore";
import { Helmet } from "react-helmet";
import Footer from "./Home/Footer";
import SimpleFooter from "./Home/SimpleFooter";
import { createPageView } from "../contexts/Service";


export default function AllEvents() {
    const [error, setError] = useState("")
    
    const [events, setEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const [baseNum, setBaseNum] = useState(12);
    const [groups, setGroups] = useState([]);
    const [filter, setFilter] = useState("");
    const [sort,setSort] = useState("date");
    const [search, setSearch] = useState("");
    const maxW = isMobile ? "100%" : "90%"

    const [eventImgs, setEventImgs] = useState([
      'https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/eventgo-main-banner.PNG'])
  
    
    let { topicName, cityName } = useParams();


    async function loadPastEvents(num){
      fires
        .collection('events')
        .where('endDate','<',firebase.firestore.Timestamp.fromDate(new Date()))
        .where('status', '==', 'live')
        .where('approved', '==', true)
        .limit(num)
        .orderBy('endDate','desc')
        .get()
        .then((snapshot) => {
            var allEvents = []
            snapshot.forEach((doc) => {
                const eventData = doc.data()
                allEvents.push({ ...eventData, id: doc.id})
            })
            setPastEvents(allEvents)
        })
    }

    async function loadCurrentEvents(){
      fires
      .collection('events')
      .where('endDate','>=',firebase.firestore.Timestamp.fromDate(new Date()))
      .where('status', '==', 'live')
      .where('approved', '==', true)
      .orderBy('endDate','asc')
      .get()
      .then((snapshot) => {
          var allEvents = []
          snapshot.forEach((doc) => {
              const eventData = doc.data()
              allEvents.push({ ...eventData, id: doc.id})
          })
          setEvents(allEvents)
      })
    }

    async function loadBannerImages(){
      fires
      .collection("settings")
      .doc("event-images")
      .get()
      .then((snapshot) => {
        const snapData = snapshot.data()
        if (snapData.bannerImages != null){
          setEventImgs(snapData.bannerImages)
        }
      })
    }

    useEffect(() => {
        window.location.assign('https://evtgo.com/')
        // loadCurrentEvents()
        // loadPastEvents(baseNum)
        // loadBannerImages()
        // createPageView()
      }, []);

    return (
      <>
      <Container
      className="d-flex justify-content-center"
      style={{ 
          minHeight: "100vh", 
          minWidth: "100vw",
          backgroundColor: "#EEEEEE" }}
    >
      <Helmet>
                <title>{'EventGo - Pioneering the Future of Events'}</title>
                <meta
                  name="description"
                  content={'EventGo - Pioneering the Future of Events'}
                />
                <meta name="twitter:card" content={'EventGo - Pioneering the Future of Events'} />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content={'Explore events'} />
                <meta
                  name="twitter:description"
                  content={'EventGo - Pioneering the Future of Events'}
                />
                <meta name="twitter:image" content={''} />
                <meta property="og:title" content={'EventGo - Pioneering the Future of Events'} />
                <meta
                  property="og:description"
                  content={'EventGo - Pioneering the Future of Events'}
                />
                <meta property="og:image" content={''} />
                <meta
                  property="og:url"
                  content={"https://evtgo.com/explore"}
                />
                <meta
                  property="og:site_name"
                  content={'EventGo - Pioneering the Future of Events'}
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
              </Helmet>

      <div 
      style={{ 
          maxWidth: maxW, 
          marginTop: '10px', 
          marginBottom: '100px' }}>

                <Carousel 
                controls={true}
                indicators={false}
                style={{
                  marginTop: '10px', 
                  height: isMobile ? (window.innerHeight / 4) : (window.innerWidth / 3),}} hidden={!isMobile}>
                    {
                      eventImgs.map((img)=>{
                        return <Carousel.Item>
                        <Card.Img 
                          src={img} 
                          height={isMobile ? (window.innerHeight / 4) : (window.innerWidth / 3)} 
                          style={{
                            objectFit: isMobile ? 'contain' : 'cover', 
                            transition: 'ease-out',
                          }}/>
                    </Carousel.Item>
                      })
                      
                    }
                 </Carousel>

                

    <div className="align-items-start" style={{marginTop: '15px'}}>
        <Card.Title style={{ fontSize: isMobile ? 17 : 25, fontWeight:'bold', color: "#000000"}}>🔥 Upcoming Events</Card.Title>
      </div>
      
      <Row md={4} style={{marginTop: '5px'}} hidden={isMobile}>
      {events.map((event) => {
        var multiPrices = false

        if (event.prices != null){
          multiPrices = true
        }
        return <Col><Card className="border-0" style={{width: '100%', marginTop: '25px', borderRadius: '0px'}}>
        <Card.Body>
        <Card.Img variant="top" style={{objectFit: 'cover', objectPosition: 'top', height: '170px'}} src={event.photoURL} />
        <div style={{marginTop: '15px'}}>
          <Card.Text style={{fontSize: '15px', fontWeight: 'bold', color: 'black', margin: 0, height: '60px'}}>{event.title}</Card.Text>
          <Card.Text style={{fontSize: '15px', fontWeight: 'bold', color: 'black', marginTop: '10px'}}>{event.priceType == "FREE" ? "FREE" : `${multiPrices ? 'From' : ''} $${event.price}CAD`}</Card.Text>                   
        </div>
        <div className="align-items-start" style={{marginTop: '15px'}}>
          <Button variant="dark" className="border-0" style={{maxWidth: '40%', height: '35px', fontSize: 12, fontWeight: 'bold', borderRadius: '0px', backgroundColor: "#f6AC19", color: 'black'}} href={"/" + event.id}>{"Book Tickets"}</Button>
        </div>
        </Card.Body>
        </Card></Col>
        })}
      </Row>

      {events.map((eventItm) => {
        var multiPrices = false
        if (eventItm.prices != null){
          multiPrices = true
        }
        return <Card className="border-0" hidden={!isMobile} style={{width: '100%', marginTop: '25px'}}>
        <Card.Body>
                            <div
                                  className="d-flex align-items-center"
                                  style={{
                                    alignSelf: "center",
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <Card.Img style={{width: '100px', height: '100px', minWidth: '100px', objectFit: 'cover', objectPosition: 'top'}} src={eventItm.photoURL} />
                                  <div style={{ marginLeft: '15px' }}>
                                    <Card.Text style={{fontSize: '17px', fontWeight: 'bold', color: 'black', margin: 0}}>{eventItm.title}</Card.Text>
                                    <Card.Text style={{fontSize: '15px', fontWeight: 'bold', color: 'black', margin: 0}}>{eventItm.priceType == "FREE" ? "FREE" : `${multiPrices ? 'From' : ''} $${eventItm.price}CAD`}</Card.Text>
                                    <div className="align-items-start" style={{marginTop: '10px'}}>
                                      <Button variant="dark" className="border-0 text-center" style={{width: '120px', height: '35px', fontSize: 12, fontWeight: 'bold', borderRadius: '0px', backgroundColor: "#f6AC19", color: 'black', alignItems: 'center', justifyContent: 'center', display: 'flex',}} href={"/" + eventItm.id}>{"Book Tickets"}</Button>
                                    </div>
                                   
                                  </div>
                                </div>
          
        </Card.Body>
        </Card>
        })}

<div className="align-items-start" style={{marginTop: '45px', marginBottom: '5px'}}>
  <Card.Title style={{ fontSize: isMobile ? 17 : 25, fontWeight:'bold', color: "#000000"}}>🔥 Events We Host</Card.Title>
</div>

<Row md={4} style={{marginTop: '5px'}} hidden={isMobile}>
      {pastEvents.map((event) => {
        var multiPrices = false

        if (event.prices != null){
          multiPrices = true
        }
        return <Col><Card className="border-0" style={{width: '100%', marginTop: '25px', borderRadius: '0px'}}>
        
        <Card.Body>
        <Card.Img variant="top" style={{objectFit: 'cover', objectPosition: 'top', height: '150px'}} src={event.photoURL} />
        <div style={{marginTop: '15px'}}>
          <Card.Text style={{fontSize: '17px', fontWeight: 'bold', color: 'black', margin: 0, height: '60px'}}>{event.title}</Card.Text>
          {/* <Card.Text style={{fontSize: '12px', fontWeight: 'normal', color: 'black', margin: 0}}>{event.address.fullAddress}</Card.Text>
          <Card.Text style={{fontSize: '12px', fontWeight: 'normal', color: 'black', margin: 0}}>{event.startDate.toDate().toLocaleString('en')}</Card.Text> */}
          <Card.Text style={{fontSize: '15px', fontWeight: 'bold', color: 'black', marginTop: '10px'}}>{event.priceType == "FREE" ? "FREE" : `${multiPrices ? 'From' : ''} $${event.price}CAD`}</Card.Text>                   
        </div>
        <div className="align-items-start" style={{marginTop: '15px'}}>
          <Button variant="dark" className="border-0" style={{width: '40%', height: '35px', fontSize: 12, fontWeight: 'bold', borderRadius: '0px', backgroundColor: "#f6AC19", color: 'black'}} href={"/" + event.id}>{"View Details"}</Button>
        </div>
        </Card.Body>
        </Card></Col>
        })}
      </Row>
                                    
                                    
                                    {pastEvents.map((eventItm) => {
                                      var multiPrices = false
                                      if (eventItm.prices != null){
                                        multiPrices = true
                                      }
                                      return <Card className="border-0" hidden={!isMobile} style={{width: '100%', marginTop: '25px'}}>
                                      <Card.Body>
                                                          <div
                                                                className="d-flex align-items-center"
                                                                style={{
                                                                  alignSelf: "center",
                                                                  display: "flex",
                                                                  width: "100%",
                                                                  marginBottom: "5px",
                                                                }}
                                                              >
                                                                <Card.Img style={{width: '100px', height: '100px', minWidth: '100px', objectFit: 'cover'}} src={eventItm.photoURL} />
                                                                <div style={{ marginLeft: '15px' }}>
                                                                  <Card.Text style={{fontSize: '17px', fontWeight: 'bold', color: 'black', margin: 0}}>{eventItm.title}</Card.Text>
                                                                  <Card.Text style={{fontSize: '15px', fontWeight: 'bold', color: 'black', margin: 0}}>{eventItm.priceType == "FREE" ? "FREE" : `${multiPrices ? 'From' : ''} $${eventItm.price}CAD`}</Card.Text>
                                                                  <div className="align-items-start" style={{marginTop: '10px'}}>
                                                                    <Button variant="dark" className="border-0 text-center" style={{width: '120px', height: '35px', fontSize: 12, fontWeight: 'bold', borderRadius: '0px', backgroundColor: "#f6AC19", color: 'black', alignItems: 'center', justifyContent: 'center', display: 'flex',}} href={"/" + eventItm.id}>{"View Details"}</Button>
                                                                  </div>
                                                                 
                                                                </div>
                                                              </div>
                                        
                                      </Card.Body>
                                      </Card>
                                      })}

      <div className="d-flex justify-content-center align-items-center" style={{marginTop: '35px'}}>
  <Button variant="outline-dark" style={{width: '150px', height: '35px', fontSize: 15, fontWeight: 'bold'}} onClick={()=>{
    const newBaseNum = baseNum + 8
    setBaseNum(newBaseNum)
    loadPastEvents(newBaseNum)
  }}>View More</Button>
</div>
<br/><br/>
    
      
      </div>
      </Container>
      <SimpleFooter />
      </>
        

    )
}
